@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

body {
	margin: 0;
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: monospace;
}

@layer components {
	.mobile-save-padding {
		padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
	}

	.spinner {
		animation: rotate 2s linear infinite;
	}
	.spinner .path {
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}

	.shadow-inner-sm {
		box-shadow: inset -3px -3px 6px 0 rgb(250, 251, 239, 1), inset 3px 3px 6px 0 rgb(22, 27, 29, 0.23);
	}

	.shadow-inner-md {
		box-shadow: inset -5px -5px 10px 0 rgb(250, 251, 239, 1), inset 5px 5px 10px 0 rgb(22, 27, 29, 0.23);
	}

	._outline-common {
		@apply focus:outline-none;
		@apply focus:ring-2 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-black;
	}

	._button-common {
		@apply _outline-common;
		@apply text-white dark:text-gray-300;
	}

	.button-light {
		@apply bg-white;
		@apply shadow-button;
	}

	.button {
		@apply _button-common;
		@apply text-gray-600 dark:text-gray-300;
		@apply bg-gray-200 dark:bg-gray-600;
		@apply hover:bg-gray-400 dark:hover:bg-gray-500;
		@apply focus:ring-gray-300 dark:focus:ring-gray-600;
	}

	.button-disabled {
		@apply _button-common;
		@apply text-gray-700 dark:text-gray-300;
		@apply bg-gray-300 dark:bg-gray-600;
		@apply cursor-not-allowed;
	}

	.button-prime {
		@apply _button-common;
		@apply bg-blue-500 dark:bg-blue-600;
		@apply hover:bg-blue-400 dark:hover:bg-blue-700;
		@apply focus:ring-blue-500 dark:focus:ring-blue-600;
	}

	.button-still {
		@apply focus:outline-none;
		@apply text-white dark:text-gray-300;
		@apply text-gray-700 dark:text-gray-300;
		@apply bg-gray-300 dark:bg-gray-600;
	}

	.button-danger {
	}

	.button-success {
	}

	.button-warning {
	}

	.button-new {
	}

	.button-xs {
		@apply p-2;
		@apply rounded-md;
	}

	.button-sm {
		@apply py-2 px-4;
		@apply rounded-md;
	}

	.button-md {
		@apply py-2 px-8;
		@apply rounded-md;
	}

	._link-common {
		@apply _outline-common;
		@apply text-white dark:text-gray-300;
	}

	.link {
		@apply _link-common;
		@apply text-gray-700 dark:text-gray-300;
		@apply hover:text-blue-700 dark:hover:text-blue-300;
		@apply focus:ring-blue-300 dark:focus:ring-blue-600;
	}

	.link-info {
	}

	.link-new {
	}

	.link-sm {
		@apply p-1;
		@apply rounded-md;
	}

	.link-xs {
		@apply rounded-md;
	}

	.link-sm {
		@apply p-2;
		@apply rounded-md;
	}

	.link-md {
		@apply py-2 px-4;
		@apply rounded-md;
	}

	._input-common {
		@apply _outline-common;
		@apply w-full block;
		@apply text-gray-900 dark:text-gray-300;
	}

	.input {
		@apply _input-common;
		@apply bg-gray-100 dark:bg-gray-800;
	}

	/*.input-danger {}*/
	/*.input-success {}*/
	/*.input-warning {}*/
	/*.input-info {}*/
	/*.input-new {}*/
	._input-group-common {
		/*@apply _outline-common;*/
		/*@apply text-white dark:text-gray-300;*/
	}

	._input-group-common input {
		/*@apply _outline-common;*/
	}

	.input-group {
		/*@apply _input-group-common;*/
		@apply text-white dark:text-gray-300;
		@apply bg-gray-100 dark:bg-gray-800;
		@apply focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-white dark:focus-within:ring-offset-black;
	}

	.input-group input {
		@apply bg-transparent;
		@apply focus:outline-none;
		/*@apply border-b-2 border-transparent focus:border-blue-600;*/
	}

	/*.input-group-danger {}*/
	/*.input-group-success {}*/
	/*.input-group-warning {}*/
	/*.input-group-info {}*/
	/*.input-group-new {}*/

	.input-md {
		@apply p-2;
		@apply rounded-md;
	}

	.input-lg {
		@apply px-2 py-3;
		@apply rounded-xl;
	}
}

@layer utilities {
	.bg-canvas {
		@apply bg-white dark:bg-gray-900;
	}

	.bg-level {
		@apply bg-gray-100 dark:bg-gray-800;
	}

	.bg-level-2 {
		@apply bg-gray-200 dark:bg-gray-700;
	}

	.bg-danger {
		@apply bg-red-400 dark:bg-red-500;
	}

	.bg-success {
		@apply bg-green-400 dark:bg-green-500;
	}

	.bg-warning {
		@apply bg-yellow-400 dark:bg-yellow-500;
	}

	.bg-info {
		@apply bg-blue-400 dark:bg-blue-500;
	}

	.bg-new {
		@apply bg-purple-400 dark:bg-purple-500;
	}

	.text-1 {
		@apply text-gray-900 dark:text-gray-50;
	}

	.text-2 {
		@apply text-gray-600 dark:text-gray-400;
	}

	.text-3 {
		@apply text-gray-400 dark:text-gray-600;
	}

	.text-disabled {
		@apply text-gray-500 dark:text-gray-500;
	}

	.text-danger {
		@apply text-red-600 dark:text-red-600;
	}

	.text-success {
	}

	.text-warning {
	}

	.text-info {
	}

	.text-new {
	}
}
